import { FC } from "react";
import { getFileUrl } from "../../../../core/utils/file.util";
import { QrImage } from "./style";

type QrProps = {
  title: string;
  img: {
    id: string;
    url: string;
    provider: string;
    mime: string;
  };
};

const Qr: FC<QrProps> = ({ title, img }) => {
  return (
    <>
      <h1 style={{ color: "rgb(0, 71, 55)" }}>{title}</h1>
      <QrImage src={getFileUrl(img.url, img.provider)}></QrImage>
    </>
  );
};

export default Qr;
