import { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RestaurantPageModel } from "../../../../../core/models/base/libertg/restaurantpage.model";
import CarouselComponent from "../../../shared/components/carousel";
import GalleryComponent from "../../../shared/components/gallery";
import Qr from "../../../shared/components/qr";
import {
  MenuInverzaLink,
  QualifyUsContainer,
} from "../../../shared/components/style";
import TitleDescriptionComponent from "../../../shared/components/titleDescription";

type RestaurantPageProps = {
  restaurantPage: RestaurantPageModel;
};

const RestaurantPage: FC<RestaurantPageProps> = ({
  restaurantPage: {
    Carousel,
    Titulo,
    Descripcion,
    Gallery,
    QrInverza,
    CartaInverza,
  },
}) => {
  return (
    <>
      <CarouselComponent Carrousel={Carousel}></CarouselComponent>
      <Container>
        <Row className="mb-5">
          <Col xl={10}>
            <TitleDescriptionComponent
              Titulo={Titulo}
              Descripcion={Descripcion}
            ></TitleDescriptionComponent>
          </Col>
          <Col xl={2}>
            <QualifyUsContainer>
              <Qr title="Calificanos" img={QrInverza}></Qr>
            </QualifyUsContainer>
          </Col>
          <Col xl={12}>
            <Container>
              <MenuInverzaLink
                href={CartaInverza.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver Carta del Restaurante
              </MenuInverzaLink>
            </Container>
          </Col>
        </Row>
        <GalleryComponent Gallery={Gallery}></GalleryComponent>
      </Container>
    </>
  );
};

export default RestaurantPage;
