import { gql } from "@apollo/client";

export const getHotelPageQuery = gql`
  query {
    hotelPage {
      id
      Carousel {
        url
        provider
        mime
      }
      Titulo
      Descripcion
      Gallery {
        id
        url
        provider
        mime
      }
      QrHotel {
        id
        url
        provider
        mime
      }
    }
  }
`;
