import { FC } from "react";
import { Container } from "react-bootstrap";

type TitleDescriptionComponentProps = {
  Titulo: string;
  Descripcion: string;
};

const TitleDescriptionComponent: FC<TitleDescriptionComponentProps> = ({
  Titulo,
  Descripcion,
}) => {
  return (
    <div>
      <Container>
        <h1 style={{ color: "rgb(0, 71, 55)" }}>{Titulo}</h1>
        <p style={{ textAlign: "justify" }}>{Descripcion}</p>
      </Container>
    </div>
  );
};

export default TitleDescriptionComponent;
