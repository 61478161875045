import { gql } from "@apollo/client";

export const getRestuarantPageQuery = gql`
  query {
    restaurantPage {
      id
      Carousel {
        url
        provider
        mime
      }
      Titulo
      Descripcion
      Gallery {
        id
        url
        provider
        mime
      }
      CartaInverza {
        id
        url
        provider
        mime
      }
      QrInverza {
        id
        url
        provider
        mime
      }
    }
  }
`;
