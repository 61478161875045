import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { EventsPageModel } from "../../../../core/models/base/libertg/eventspage.model";
import { HabitacionModel } from "../../../../core/models/base/libertg/habitacion.model";
import { HotelPageModel } from "../../../../core/models/base/libertg/hotelpage.model";
import { LayoutModel } from "../../../../core/models/base/libertg/layout.model";
import { RestaurantPageModel } from "../../../../core/models/base/libertg/restaurantpage.model";
import { RutaModel } from "../../../../core/models/base/libertg/ruta.model";
import { SpaPageModel } from "../../../../core/models/base/libertg/spapage.model";
import { TipoHabitacionModel } from "../../../../core/models/base/libertg/tipohabitacion.model";
import { AppState } from "../../../../core/models/state/libertg/app.state.model";
import RestaurantPage from "../../views/restaurant/container/resturant";
import SpaPage from "../../views/spa/container/spa";
import Footer from "../components/footer";
import Header from "../components/header";
import Loading from "../components/loading";
import {
  getEventsPage$,
  getHabitaciones$,
  getHotelPage$,
  getLayout$,
  getResturantPage$,
  getRutas$,
  getSpaPage$,
  getTiposHabitacion$,
} from "../store/layout.effects";

const Layout: FC = () => {
  const dispatch = useDispatch();
  const layout: LayoutModel = useSelector(
    (state: AppState) => state.layoutState.layout
  );
  const hotelPage: HotelPageModel = useSelector(
    (state: AppState) => state.layoutState.hotelPage
  );
  const resturantPage: RestaurantPageModel = useSelector(
    (state: AppState) => state.layoutState.restaurantPage
  );
  const spaPage: SpaPageModel = useSelector(
    (state: AppState) => state.layoutState.spaPage
  );
  const eventsPage: EventsPageModel = useSelector(
    (state: AppState) => state.layoutState.eventsPage
  );
  const habitaciones: HabitacionModel[] = useSelector(
    (state: AppState) => state.layoutState.habitaciones
  );
  const tiposHabitacion: TipoHabitacionModel[] = useSelector(
    (state: AppState) => state.layoutState.tiposHabitacion
  );
  const rutas: RutaModel[] = useSelector(
    (state: AppState) => state.layoutState.rutas
  );

  useEffect(() => {
    if (layout === null) {
      const load = () => dispatch(getLayout$());
      load();
    }

    if (hotelPage === null) {
      const load = () => dispatch(getHotelPage$());
      load();
    }

    if (resturantPage === null) {
      const load = () => dispatch(getResturantPage$());
      load();
    }

    if (spaPage === null) {
      const load = () => dispatch(getSpaPage$());
      load();
    }

    if (eventsPage === null) {
      const load = () => dispatch(getEventsPage$());
      load();
    }

    if (habitaciones.length === 0) {
      const load = () => dispatch(getHabitaciones$());
      load();
    }

    if (tiposHabitacion.length === 0) {
      const load = () => dispatch(getTiposHabitacion$());
      load();
    }

    if (rutas.length === 0) {
      const load = () => dispatch(getRutas$());
      load();
    }
    // eslint-disable-next-line
  }, [layout, hotelPage, resturantPage, habitaciones, tiposHabitacion, rutas]);

  const HotelPage = lazy(() => import("../../views/hotel/container/hotel"));
  const RoomPage = lazy(() => import("../../views/room/container/room"));
  const EventsPage = lazy(() => import("../../views/events/container/events"));

  const getHabitacionesPorTipo = (tipo: TipoHabitacionModel) =>
    habitaciones.filter((h) => h.tipohabitacion.id === tipo.id);

  const mapRoutes = (): JSX.Element[] =>
    rutas.map((ruta) => {
      switch (ruta.ruta) {
        case "/":
          return (
            <Route
              key={ruta.id}
              path={ruta.ruta}
              element={
                <Suspense fallback={<>...</>}>
                  <HotelPage hotelPage={hotelPage}></HotelPage>
                </Suspense>
              }
            />
          );
        case "/restaurant":
          return (
            <Route
              key={ruta.id}
              path={ruta.ruta}
              element={
                <Suspense fallback={<>...</>}>
                  <RestaurantPage
                    restaurantPage={resturantPage}
                  ></RestaurantPage>
                </Suspense>
              }
            />
          );
        case "/spa":
          return (
            <Route
              key={ruta.id}
              path={ruta.ruta}
              element={
                <Suspense fallback={<>...</>}>
                  <SpaPage spaPage={spaPage}></SpaPage>
                </Suspense>
              }
            />
          );
        case "/roomsElite":
        case "/roomsPlatino":
        case "/roomsGolden":
        case "/roomRoyal":
          return (
            <Route
              key={ruta.id}
              path={ruta.ruta}
              element={
                <Suspense fallback={<>...</>}>
                  <RoomPage
                    tipoHabitacion={ruta.tipohabitacion}
                    habitaciones={getHabitacionesPorTipo(ruta.tipohabitacion)}
                  />
                </Suspense>
              }
            />
          );
        case "/wedding":
          return (
            <Route
              key={ruta.id}
              path={ruta.ruta}
              element={
                <Suspense fallback={<>...</>}>
                  <EventsPage eventsPage={eventsPage} />
                </Suspense>
              }
            />
          );
        default:
          return (
            <Route
              key={ruta.id}
              path={ruta.ruta}
              element={
                <Suspense fallback={<div></div>}>
                  <HotelPage hotelPage={hotelPage}></HotelPage>
                </Suspense>
              }
            />
          );
      }
    });

  return layout && rutas && hotelPage ? (
    <>
      <Header layout={layout} />
      <div className="be-panel"></div>
      <Routes>{layout.rutas.length > 0 ? mapRoutes() : null}</Routes>
      <Footer layout={layout} />
      <div className="comunication-container">
        <a
          href="https://api.whatsapp.com/send?phone=573008221113&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Libertg%20Hotels."
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </div>
    </>
  ) : (
    <Loading />
  );
};

export default Layout;
