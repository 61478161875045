import { EventsPageModel } from "../../../../core/models/base/libertg/eventspage.model";
import { HabitacionModel } from "../../../../core/models/base/libertg/habitacion.model";
import { HotelPageModel } from "../../../../core/models/base/libertg/hotelpage.model";
import { LayoutModel } from "../../../../core/models/base/libertg/layout.model";
import { RestaurantPageModel } from "../../../../core/models/base/libertg/restaurantpage.model";
import { RutaModel } from "../../../../core/models/base/libertg/ruta.model";
import { SpaPageModel } from "../../../../core/models/base/libertg/spapage.model";
import { TipoHabitacionModel } from "../../../../core/models/base/libertg/tipohabitacion.model";
import { IAction } from "../../../../core/models/state/action.model";
import {
  Get_Events_Page,
  Get_Events_Page_Success,
  Get_Habitaciones,
  Get_Habitaciones_Success,
  Get_Hotel_Page,
  Get_Hotel_Page_Success,
  Get_Layout,
  Get_Layout_Success,
  Get_Restaurant_Page,
  Get_Restaurant_Page_Success,
  Get_Rutas,
  Get_Rutas_Success,
  Get_Spa_Page,
  Get_Spa_Page_Success,
  Get_Tipos_Habitacion,
  Get_Tipos_Habitacion_Success,
  On_Error,
} from "./layout.actiontypes";

export const GetLayout = () =>
  ({
    type: Get_Layout,
    payload: true,
  } as IAction);

export const GetLayoutSuccess = (layout: LayoutModel) =>
  ({
    type: Get_Layout_Success,
    payload: layout,
  } as IAction);

export const GetHotelPage = () =>
  ({
    type: Get_Hotel_Page,
    payload: true,
  } as IAction);

export const GetHotelPageSuccess = (hotelPage: HotelPageModel) =>
  ({
    type: Get_Hotel_Page_Success,
    payload: hotelPage,
  } as IAction);

export const GetRestaurantPage = () =>
  ({
    type: Get_Restaurant_Page,
    payload: true,
  } as IAction);

export const GetRestaurantPageSuccess = (restaurantPage: RestaurantPageModel) =>
  ({
    type: Get_Restaurant_Page_Success,
    payload: restaurantPage,
  } as IAction);

export const GetSpaPage = () =>
  ({
    type: Get_Spa_Page,
    payload: true,
  } as IAction);

export const GetSpaPageSuccess = (spaPage: SpaPageModel) =>
  ({
    type: Get_Spa_Page_Success,
    payload: spaPage,
  } as IAction);

export const GetEventsPage = () =>
  ({
    type: Get_Events_Page,
    payload: true,
  } as IAction);

export const GetEventsPageSuccess = (eventsPage: EventsPageModel) =>
  ({
    type: Get_Events_Page_Success,
    payload: eventsPage,
  } as IAction);

export const GetHabitaciones = () =>
  ({
    type: Get_Habitaciones,
    payload: true,
  } as IAction);

export const GetHabitacionesSuccess = (habitaciones: HabitacionModel[]) =>
  ({
    type: Get_Habitaciones_Success,
    payload: habitaciones,
  } as IAction);

export const GetTiposHabitacion = () =>
  ({
    type: Get_Tipos_Habitacion,
    payload: true,
  } as IAction);

export const GetTiposHabitacionSuccess = (
  tiposHabitacion: TipoHabitacionModel[]
) =>
  ({
    type: Get_Tipos_Habitacion_Success,
    payload: tiposHabitacion,
  } as IAction);

export const GetRutas = () =>
  ({
    type: Get_Rutas,
    payload: true,
  } as IAction);

export const GetRutasSuccess = (rutas: RutaModel[]) =>
  ({
    type: Get_Rutas_Success,
    payload: rutas,
  } as IAction);

export const OnError = (error: any) =>
  ({
    type: On_Error,
    payload: error,
  } as IAction);
