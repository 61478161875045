import { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SpaPageModel } from "../../../../../core/models/base/libertg/spapage.model";
import CarouselComponent from "../../../shared/components/carousel";
import GalleryComponent from "../../../shared/components/gallery";
import Qr from "../../../shared/components/qr";
import { QualifyUsContainer } from "../../../shared/components/style";
import TitleDescriptionComponent from "../../../shared/components/titleDescription";

type SpaPageProps = {
  spaPage: SpaPageModel;
};

const SpaPage: FC<SpaPageProps> = ({
  spaPage: { Carousel, Titulo, Descripcion, Gallery, QrSpa },
}) => {
  return (
    <>
      <CarouselComponent Carrousel={Carousel}></CarouselComponent>
      <Container>
        <Row>
          <Col xl={10}>
            <TitleDescriptionComponent
              Titulo={Titulo}
              Descripcion={Descripcion}
            ></TitleDescriptionComponent>
          </Col>
          <Col xl={2}>
            <QualifyUsContainer>
              <Qr title="Calificanos" img={QrSpa}></Qr>
            </QualifyUsContainer>
          </Col>
        </Row>
        <GalleryComponent Gallery={Gallery}></GalleryComponent>
      </Container>
    </>
  );
};

export default SpaPage;
