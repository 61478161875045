import { FC } from "react";
import { getFileUrl } from "../../../../core/utils/file.util";
import { CustomImageGallery } from "./style";

type ImgGalleryProps = {
  img: {
    id: string;
    url: string;
    provider: string;
  };
  index: number;
};

const ImgGallery: FC<ImgGalleryProps> = ({ img }) => {
  return <CustomImageGallery src={getFileUrl(img.url, img.provider)} />;
};

export default ImgGallery;
